@import url('https://fonts.googleapis.com/css2?family=Benne&family=Bentham&display=swap');

.question {
  text-align: center;
  font-family: 'Bentham', serif;
  font-weight: 300;
  font-size: 5vw;
  color: white;
  padding: 0;
  
}
.poem-container {
  justify-content: center;
  max-height: 100%;
  margin-top: 2em;
  border: 3px;
  border-style: solid;
  border-color: black;
  padding: 1em;
  margin-left: 2vw;
  margin-right: 2vw;
  background-color: white;

  

}
.author {
  color: black;
  font-family: 'Benne', serif;
  line-height: normal;
  
}

.title{
  color:black;
  font-family: 'Benne', serif;
  font-size: 40px;
  line-height: normal;
}

.poem {
  color: black;
  font-family: 'Bentham', serif;
  font-size: 20px;
  line-height: normal;
  
}

@media screen and (min-device-width : 320px) and (max-device-width : 420px) {
  .question{
    font-size: 40px;
  }
}

