@import url('https://fonts.googleapis.com/css2?family=Bellota&family=Benne&family=Bentham&display=swap');

label {
  font-size: 30px;
}

.addyou {
  text-align: center;
  margin-left: 25em;
  margin-right: 25em;
  margin-top: 2em;
  border: 2px groove black;
  background: #8A708C;
  color: white;
  border: 3px solid white;
}

.greeting {
  font-family: 'Benne', serif;
  font-size: 5em;
  margin-top: .5em;
  padding: 0;
  margin-bottom: 0;
  
}

.form-description{
  font-family: 'Benne', serif;
  font-size: 1.6em;
  margin-right: 7em;
  margin-left: 7em;
  text-overflow: inherit;
  margin-top: 0;
  margin-bottom: .9em;
  border-bottom: 2px solid white;
}

.form {
  display:flex;
  flex-direction: column;
  font-family: 'Benne', serif;
  margin-left: 5em;
  margin-right: 5em;
}

#title{
  font-family: 'Bentham', serif;
  font-size: 30px;
  height: 2.2em;
  margin-left: 8em;
  margin-right: 8em;
  margin-bottom: 1em;
  background: white;
  border: 2px solid white;
  color: #3F3340;
}

#author {
  font-family: 'Bentham', serif;
  font-size: 30px;
  height: 2.5em;
  margin-left: 8em;
  margin-right: 8em;
  margin-bottom: 1em;
  background: white;
  text-align: center;
  border: 2px solid white;
  color: #3F3340;
}

#location {
  font-family: 'Bentham', serif;
  font-size: 30px;
  height: 2em;
  margin-left: 8em;
  margin-right: 8em;
  margin-bottom: 1em;
  text-align: center;
  background: #BD9BBF;
  border: 2px solid white;
  color: #3F3340;
}

#poem {
  font-family: 'Bentham', serif;
  font-size: 30px;
  margin-left: 8em;
  margin-right: 8em;
  margin-bottom:1em;
  height: 20em;
  text-overflow: inherit;
  word-wrap: break-word;
  word-break: break-all;
  background: #BD9BBF;
  border: 2px solid white;
  color: #3F3340;
}


.dropdown {
  max-width: 20em;
  margin-left: 8em;
  margin-right: 8em;
  font-size: 30px;
  text-align: center;
  background-color: #BD9BBF;
  border: 2px solid white;
  color: white;
}

#share{
  margin-top: 1em;
  margin-left: 8em;
  margin-right: 2em;
  margin-bottom: 1em;
  text-decoration: none;
  display:block;
  width: 15vw;
  height:140px;
  line-height:80px;
  border: .2em double #f5f5f5;
  border-radius: 50%;
  color:#f5f5f5;
  text-align:center;
  text-decoration:none;
  background: #645266;
  box-shadow: 0 0 3px gray;
  font-size:40px;
  font-weight:bold;
}

@media screen and (max-width: 1025px) {
  .container {
    width: 1200;
  }

  .addyou {
    margin-left: 10vw;
    margin-right: 10vw;
    
  }

  #title {
    font-size: 20px;
    margin-left: 5em;
    margin-right: 5em;
  }

  #poem {
    font-size: 20px;
    margin-left: 5em;
    margin-right: 5em;
  }

  #author {
    font-size: 20px;
    margin-left: 5em;
    margin-right: 5em;
  }

  #location {
    font-size: 20px;
    margin-left: 5em;
    margin-right: 5em;
  }

  .dropdown {
    font-size: 20px;
    margin-left: 7.3em;
    margin-right: 7em;
  }

  #share {
    margin-left: 5.5em;
    width: 20vw;
    height:120px;
  }
} 

@media screen and (min-device-width : 320px) and (max-device-width : 420px) {
  .container {
    width: 320px;
  }

  .addyou {
    margin-left: 10vw;
    margin-right: 10vw;
    
  }

  #title {
    font-size: 15px;
    margin-left: 1em;
    margin-right: 1em;
  }

  #poem {
    font-size: 20px;
    margin-left: 5em;
    margin-right: 5em;
  }

  #author {
    font-size: 20px;
    margin-left: 5em;
    margin-right: 5em;
  }

  #location {
    font-size: 20px;
    margin-left: 5em;
    margin-right: 5em;
  }

  .dropdown {
    font-size: 20px;
    margin-left: 7.3em;
    margin-right: 7em;
  }

  #share {
    margin-left: 5.5em;
    width: 20vw;
    height:120px;
  }

}