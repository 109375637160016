@import url('https://fonts.googleapis.com/css2?family=Benne&family=Bentham&display=swap');

body, html {
  height: 100%;
}

.infoContainer {
  position: relative;
  background-color: #DEB9B1;
  background-image: url(https://i.imgur.com/F4p0TdK.jpg);
}

.buttonContainer {
  padding-top: 1vh;
  padding-bottom: 5vh;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto auto;
  row-gap: 2em;
  column-gap: 6vw;
  justify-content: center;
  height: 100%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-bottom: 3px solid black;
  
}


.custom-btn {
  font-family: 'Benne', serif;
  text-decoration: none;
  display:block;
  width: 15vw;
  height:140px;
  line-height:80px;
  border: .2em double #f5f5f5;
  border-radius: 50%;
  color:#f5f5f5;
  text-align:center;
  text-decoration:none;
  background: #555777;
  box-shadow: 0 0 3px gray;
  font-size:40px;
  font-weight:bold;
  
}

.custom-btn:hover {
color: black;
border-color: white;
transition: all 0.4s ease 0s;
}

.info {
  margin-top: 1em;
  margin-bottom: 1em;
  padding-top: .5em;
  padding-bottom: 2.3em;
  margin-left: 25em;
  margin-right: 25em;
  text-align: center;
  border: 3px solid black;
  background-color: #ABC5A0;
}

#humorous {
  background: #CAD2C6;
}
#humorous:hover{
  background:#8C9189;
  color: white;
}

#romantic {
  background: #C5A8A0;
}

#romantic:hover {
  background: #85716B;
  color: white;
}

#melancholy {
  background: #A0BDC5;
  color: white;
}
#melancholy:hover {
  background: #6B7F85;
  color: white;

}

#inspired {
  background: #C1C391;
}

#inspired:hover {
  background: #818260;
  color: white;
}

#whimsical {
  background: #7D7479;
}
#whimsical:hover {
  background: #3D393B;
  color: white;
}

#idyllic {
  background: #BD9BBF;
}

#idyllic:hover {
  background:#7E6780;
  color: white;
}

#sassy {
  background: #C391A8;
}

#sassy:hover {
  background: #826070;
  color: white;
}

#reflective {
  background: #ABC5A0;
}

#reflective:hover {
  background: #73856B;
  color: white;
}

@media screen and (max-width: 1025px) {
  .custom-btn {
    width: 20vw;
    height: 120px;
  }
  .info {
    width: 80vw;
    margin-left: 10vw;
  }
}

@media screen and (min-device-width : 320px) and (max-device-width : 420px) {
  .custom-btn {
    font-family: 'Benne', serif;
    text-decoration: none; 
    width: 90vw;
    border: .2em double #f5f5f5;
    border-radius: 1%;
    color:#f5f5f5;
    text-align:center;
    text-decoration:none;
    background: #555777;
    box-shadow: 0 0 3px gray;
    font-size:40px;
    font-weight:bold;
    
  }

  .buttonContainer {
    margin-left: 5vw;
    padding-top: 1vh;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: .5em;
    column-gap: 6vw;
    height: 100%;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-bottom: none;
    
  }

}


