@import url('https://fonts.googleapis.com/css2?family=Bellota&family=Benne&family=Bentham&display=swap');


a:link {
text-decoration: none;
text-align: center;
color: black;
}


a:active {
  color: darkturquoise;
}

#quote {
  margin-right: 9rem;
  grid-area: C;
  font-family: 'Bentham', serif;
  font-size: 28px;
  align-items: center;
  float: right;

}

.nav {
  border-bottom: 3px groove black;
  padding-top: 0;
  align-items: center;
  color: black;
  background: white;
  justify-content: space-between;
}


#name {
  float: left;
  color: black;
  align-items: center;
  flex-direction: row;
  justify-content: left;
  font-family: 'Benne', serif;
  font-size: 80px;
  margin-left: 9vw;
  padding-top: 3px;
  

}

#name:hover{
  color: #7EC7E2;
}

#quill {
  grid-area: A;
  height: 4.5em;
  
  margin-left: 5vw;
  
}

@media screen and (max-width: 1025px) {
  .nav {
    justify-content: center;
    text-align: center;
    align-content: center;
  }

  #name {
    margin: 0;
    line-height: 1.5em;
    padding: 0;
  }

  #quote {
  margin-left: 21vw;
  margin-top: 0;
  }
}

@media screen and (min-device-width : 320px) and (max-device-width : 420px) {
  #quote {
    margin: 0;
  }

  #name {
    margin-top: .5em;
    padding: 0;
    line-height: .8em;
  }
}