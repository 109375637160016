@import url(https://fonts.googleapis.com/css2?family=Bellota&family=Benne&family=Bentham&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Benne&family=Bentham&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Benne&family=Bentham&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bellota&family=Benne&family=Bentham&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bellota&family=Benne&family=Bentham&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bellota&family=Benne&family=Bentham&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bellota&family=Benne&family=Bentham&display=swap);
a:link {
text-decoration: none;
text-align: center;
color: black;
}


a:active {
  color: darkturquoise;
}

#quote {
  margin-right: 9rem;
  grid-area: C;
  font-family: 'Bentham', serif;
  font-size: 28px;
  -webkit-align-items: center;
          align-items: center;
  float: right;

}

.nav {
  border-bottom: 3px groove black;
  padding-top: 0;
  -webkit-align-items: center;
          align-items: center;
  color: black;
  background: white;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}


#name {
  float: left;
  color: black;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: left;
          justify-content: left;
  font-family: 'Benne', serif;
  font-size: 80px;
  margin-left: 9vw;
  padding-top: 3px;
  

}

#name:hover{
  color: #7EC7E2;
}

#quill {
  grid-area: A;
  height: 4.5em;
  
  margin-left: 5vw;
  
}

@media screen and (max-width: 1025px) {
  .nav {
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    -webkit-align-content: center;
            align-content: center;
  }

  #name {
    margin: 0;
    line-height: 1.5em;
    padding: 0;
  }

  #quote {
  margin-left: 21vw;
  margin-top: 0;
  }
}

@media screen and (min-device-width : 320px) and (max-device-width : 420px) {
  #quote {
    margin: 0;
  }

  #name {
    margin-top: .5em;
    padding: 0;
    line-height: .8em;
  }
}
.question {
  text-align: center;
  font-family: 'Bentham', serif;
  font-weight: 300;
  font-size: 5vw;
  color: white;
  padding: 0;
  
}
.poem-container {
  -webkit-justify-content: center;
          justify-content: center;
  max-height: 100%;
  margin-top: 2em;
  border: 3px;
  border-style: solid;
  border-color: black;
  padding: 1em;
  margin-left: 2vw;
  margin-right: 2vw;
  background-color: white;

  

}
.author {
  color: black;
  font-family: 'Benne', serif;
  line-height: normal;
  
}

.title{
  color:black;
  font-family: 'Benne', serif;
  font-size: 40px;
  line-height: normal;
}

.poem {
  color: black;
  font-family: 'Bentham', serif;
  font-size: 20px;
  line-height: normal;
  
}

@media screen and (min-device-width : 320px) and (max-device-width : 420px) {
  .question{
    font-size: 40px;
  }
}


body, html {
  height: 100%;
}

.infoContainer {
  position: relative;
  background-color: #DEB9B1;
  background-image: url(https://i.imgur.com/F4p0TdK.jpg);
}

.buttonContainer {
  padding-top: 1vh;
  padding-bottom: 5vh;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 2em;
  row-gap: 2em;
  grid-column-gap: 6vw;
  -webkit-column-gap: 6vw;
          column-gap: 6vw;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-bottom: 3px solid black;
  
}


.custom-btn {
  font-family: 'Benne', serif;
  text-decoration: none;
  display:block;
  width: 15vw;
  height:140px;
  line-height:80px;
  border: .2em double #f5f5f5;
  border-radius: 50%;
  color:#f5f5f5;
  text-align:center;
  text-decoration:none;
  background: #555777;
  box-shadow: 0 0 3px gray;
  font-size:40px;
  font-weight:bold;
  
}

.custom-btn:hover {
color: black;
border-color: white;
transition: all 0.4s ease 0s;
}

.info {
  margin-top: 1em;
  margin-bottom: 1em;
  padding-top: .5em;
  padding-bottom: 2.3em;
  margin-left: 25em;
  margin-right: 25em;
  text-align: center;
  border: 3px solid black;
  background-color: #ABC5A0;
}

#humorous {
  background: #CAD2C6;
}
#humorous:hover{
  background:#8C9189;
  color: white;
}

#romantic {
  background: #C5A8A0;
}

#romantic:hover {
  background: #85716B;
  color: white;
}

#melancholy {
  background: #A0BDC5;
  color: white;
}
#melancholy:hover {
  background: #6B7F85;
  color: white;

}

#inspired {
  background: #C1C391;
}

#inspired:hover {
  background: #818260;
  color: white;
}

#whimsical {
  background: #7D7479;
}
#whimsical:hover {
  background: #3D393B;
  color: white;
}

#idyllic {
  background: #BD9BBF;
}

#idyllic:hover {
  background:#7E6780;
  color: white;
}

#sassy {
  background: #C391A8;
}

#sassy:hover {
  background: #826070;
  color: white;
}

#reflective {
  background: #ABC5A0;
}

#reflective:hover {
  background: #73856B;
  color: white;
}

@media screen and (max-width: 1025px) {
  .custom-btn {
    width: 20vw;
    height: 120px;
  }
  .info {
    width: 80vw;
    margin-left: 10vw;
  }
}

@media screen and (min-device-width : 320px) and (max-device-width : 420px) {
  .custom-btn {
    font-family: 'Benne', serif;
    text-decoration: none; 
    width: 90vw;
    border: .2em double #f5f5f5;
    border-radius: 1%;
    color:#f5f5f5;
    text-align:center;
    text-decoration:none;
    background: #555777;
    box-shadow: 0 0 3px gray;
    font-size:40px;
    font-weight:bold;
    
  }

  .buttonContainer {
    margin-left: 5vw;
    padding-top: 1vh;
    padding-bottom: 5vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    grid-row-gap: .5em;
    row-gap: .5em;
    grid-column-gap: 6vw;
    -webkit-column-gap: 6vw;
            column-gap: 6vw;
    height: 100%;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-bottom: none;
    
  }

}



label {
  font-size: 30px;
}

.addyou {
  text-align: center;
  margin-left: 25em;
  margin-right: 25em;
  margin-top: 2em;
  border: 2px groove black;
  background: #8A708C;
  color: white;
  border: 3px solid white;
}

.greeting {
  font-family: 'Benne', serif;
  font-size: 5em;
  margin-top: .5em;
  padding: 0;
  margin-bottom: 0;
  
}

.form-description{
  font-family: 'Benne', serif;
  font-size: 1.6em;
  margin-right: 7em;
  margin-left: 7em;
  text-overflow: inherit;
  margin-top: 0;
  margin-bottom: .9em;
  border-bottom: 2px solid white;
}

.form {
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-family: 'Benne', serif;
  margin-left: 5em;
  margin-right: 5em;
}

#title{
  font-family: 'Bentham', serif;
  font-size: 30px;
  height: 2.2em;
  margin-left: 8em;
  margin-right: 8em;
  margin-bottom: 1em;
  background: white;
  border: 2px solid white;
  color: #3F3340;
}

#author {
  font-family: 'Bentham', serif;
  font-size: 30px;
  height: 2.5em;
  margin-left: 8em;
  margin-right: 8em;
  margin-bottom: 1em;
  background: white;
  text-align: center;
  border: 2px solid white;
  color: #3F3340;
}

#location {
  font-family: 'Bentham', serif;
  font-size: 30px;
  height: 2em;
  margin-left: 8em;
  margin-right: 8em;
  margin-bottom: 1em;
  text-align: center;
  background: #BD9BBF;
  border: 2px solid white;
  color: #3F3340;
}

#poem {
  font-family: 'Bentham', serif;
  font-size: 30px;
  margin-left: 8em;
  margin-right: 8em;
  margin-bottom:1em;
  height: 20em;
  text-overflow: inherit;
  word-wrap: break-word;
  word-break: break-all;
  background: #BD9BBF;
  border: 2px solid white;
  color: #3F3340;
}


.dropdown {
  max-width: 20em;
  margin-left: 8em;
  margin-right: 8em;
  font-size: 30px;
  text-align: center;
  background-color: #BD9BBF;
  border: 2px solid white;
  color: white;
}

#share{
  margin-top: 1em;
  margin-left: 8em;
  margin-right: 2em;
  margin-bottom: 1em;
  text-decoration: none;
  display:block;
  width: 15vw;
  height:140px;
  line-height:80px;
  border: .2em double #f5f5f5;
  border-radius: 50%;
  color:#f5f5f5;
  text-align:center;
  text-decoration:none;
  background: #645266;
  box-shadow: 0 0 3px gray;
  font-size:40px;
  font-weight:bold;
}

@media screen and (max-width: 1025px) {
  .container {
    width: 1200;
  }

  .addyou {
    margin-left: 10vw;
    margin-right: 10vw;
    
  }

  #title {
    font-size: 20px;
    margin-left: 5em;
    margin-right: 5em;
  }

  #poem {
    font-size: 20px;
    margin-left: 5em;
    margin-right: 5em;
  }

  #author {
    font-size: 20px;
    margin-left: 5em;
    margin-right: 5em;
  }

  #location {
    font-size: 20px;
    margin-left: 5em;
    margin-right: 5em;
  }

  .dropdown {
    font-size: 20px;
    margin-left: 7.3em;
    margin-right: 7em;
  }

  #share {
    margin-left: 5.5em;
    width: 20vw;
    height:120px;
  }
} 

@media screen and (min-device-width : 320px) and (max-device-width : 420px) {
  .container {
    width: 320px;
  }

  .addyou {
    margin-left: 10vw;
    margin-right: 10vw;
    
  }

  #title {
    font-size: 15px;
    margin-left: 1em;
    margin-right: 1em;
  }

  #poem {
    font-size: 20px;
    margin-left: 5em;
    margin-right: 5em;
  }

  #author {
    font-size: 20px;
    margin-left: 5em;
    margin-right: 5em;
  }

  #location {
    font-size: 20px;
    margin-left: 5em;
    margin-right: 5em;
  }

  .dropdown {
    font-size: 20px;
    margin-left: 7.3em;
    margin-right: 7em;
  }

  #share {
    margin-left: 5.5em;
    width: 20vw;
    height:120px;
  }

}
label {
  font-size: 30px;
}

.addfav {
  text-align: center;
  margin-left: 25em;
  margin-right: 25em;
  margin-top: 2em;
  border: 2px groove black;
  background: #A87D91;
  color: white;
  border: 3px solid white;
}

.greeting {
  font-family: 'Benne', serif;
  font-size: 5em;
  margin-top: .5em;
  padding: 0;
  margin-bottom: 0;
  
}

.form-description{
  font-family: 'Benne', serif;
  font-size: 1.6em;
  margin-right: 7em;
  margin-left: 7em;
  text-overflow: inherit;
  margin-top: 0;
  margin-bottom: .9em;
  border-bottom: 2px solid white;
}

.form {
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-family: 'Benne', serif;
  margin-left: 5em;
  margin-right: 5em;
}

#title{
  text-align: center;
  font-family: 'Bentham', serif;
  font-size: 30px;
  height: 2.2em;
  margin-left: 8em;
  margin-right: 8em;
  margin-bottom: 1em;
  background: white;
  border: 2px solid black;
  color: black;
}

#author {
  font-family: 'Bentham', serif;
  font-size: 30px;
  height: 2.5em;
  margin-left: 8em;
  margin-right: 8em;
  margin-bottom: 1em;
  background: white;
  text-align: center;
  border: 2px solid black;
  color: black;
}

#location {
  font-family: 'Bentham', serif;
  font-size: 30px;
  height: 2em;
  margin-left: 8em;
  margin-right: 8em;
  margin-bottom: 1em;
  text-align: center;
  background: white;
  border: 2px solid black;
  color: #3F3340;
}

#poem {
  display: inline-block;
  font-family: 'Bentham', serif;
  font-size: 30px;
  margin-left: 8em;
  margin-right: 8em;
  margin-bottom:1em;
  height: 20em;
  word-break: break-word;
  background: white;
  border: 2px solid black;
  color: black;
}



.dropdown {
  max-width: 20em;
  margin-left: 8em;
  margin-right: 8em;
  font-size: 30px;
  text-align: center;
  background-color: white;
  border: 2px solid black;
  color: black;
}

#share{
  margin-top: 1em;
  margin-left: 17vw;
  margin-right: 4vw;
  margin-bottom: 1em;
  text-decoration: none;
  display:block;
  width: 15vw;
  height:140px;
  line-height:80px;
  border: .2em double #f5f5f5;
  border-radius: 50%;
  color:#f5f5f5;
  text-align:center;
  text-decoration:none;
  background: #826070;
  box-shadow: 0 0 3px gray;
  font-size:40px;
  font-weight:bold;
}


@media screen and (max-width: 1025px) {
  .container {
    width: 1200;
  }

  .addfav {
    margin-left: 10vw;
    margin-right: 10vw;
    
  }

  #title {
    font-size: 20px;
    margin-left: 5em;
    margin-right: 5em;
  }

  #poem {
    font-size: 20px;
    margin-left: 5em;
    margin-right: 5em;
  }

  #author {
    font-size: 20px;
    margin-left: 5em;
    margin-right: 5em;
  }

  #location {
    font-size: 20px;
    margin-left: 5em;
    margin-right: 5em;
  }

  .dropdown {
    font-size: 20px;
    margin-left: 7.3em;
    margin-right: 7em;
  }

  #share {
    margin-left: 5.5em;
    width: 20vw;
    height:120px;
  }
} 

@media screen and (min-device-width : 320px) and (max-device-width : 420px) {
  .container {
    width: 320px;
  }

  .greeting {
    font-size: 3em;
  }

  .form-description {
    font-size: 1.5em;
    margin: 0;
  }

  .addyou {
    margin-left: 10vw;
    margin-right: 10vw;
    
  }

  .form {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-family: 'Benne', serif;
    margin-left: 1em;
    margin-right: 1em;
  }

  #title {
    font-size: 20px;
    margin-right: 0;
    margin-left: 0;
    width: 70vw;
    text-align: center;
  }

  #poem {
    font-size: 20px;
    margin-right: 0;
    margin-left: 0;
    width: 70vw;
    text-align: center;
  }

  #author {
    font-size: 20px;
    margin-right: 0;
    margin-left: 0;
    width: 70vw;
    text-align: center;
  }

  #location {
    font-size: 20px;
    margin-right: 0;
    margin-left: 0;
    width: 70vw;
    text-align: center;
  }

  .dropdown {
    font-size: 20px;
    margin-right: 0;
    margin-left: 0;
    width: 70vw;
    text-align: center;
  }

  #share {
    font-size: 20px;
    margin-right: 0;
    margin-left: 0;
    width: 70vw;
    text-align: center;
  }

}


.card{
  background: white;
  width: 22rem;
  border: 2px groove black;
}

.card:hover{
  box-shadow: 5px 10px 20px 1px rgba(0,0,0,0.253)!important;
}

.card-body{
  padding: 3rem 0 !important;
}

.card-text{
  font-size: 1.5rem;
  padding: 0.4rem 1.9rem;
  font-family: 'Benne', serif;
  color: black;
}

.card-title {
font-family: 'Bentham', serif;
font-weight: 300;
font-size: 2.2rem;
border-bottom: 2px groove black;
border-top: 2px groove black;
}

.container-fluid.row {
  padding-top: 6rem;
}

.overflow{
  overflow: hidden;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.card-img-top{
  -webkit-transform: scale(1);
          transform: scale(1);
}

.card-img.top:hover{
  -webkit-transform: scale(1.8);
          transform: scale(1.8);
}

.row {
  --bs-gutter-x: 6.0rem;
}

.text-secondary {
  color: black!important;
}

@media screen and (max-width: 1025px) {
  .row {
    --bs-gutter-x: 1.8rem;
  }

  .card{
    background: white;
    width: 15rem;
    border: 2px groove black;
  }

  .card-text{
    font-size: 44px;
    padding: 0.4rem 1.9rem;
    font-family: 'Benne', serif;
    color: black;
  }

  .card-title {
    font-size: 57px;
  }
}

@media screen and (min-device-width : 320px) and (max-device-width : 420px) {
.card {
  margin-left: 15vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.card-text{
  font-size: 25px;
  padding: 0.4rem;
  font-family: 'Benne', serif;
  color: black;
}

.card-title {
  font-size: 45px;
}
}
body {
  background-color: #DEC385;
}

.browseall {
  text-align: center;
  font-family: 'Benne', Serif;
  font-size: 5em;
}

.sip {
  text-align: center;
  font-family: 'Benne', Serif;
  font-size: 1.6em;
}


.footer {
  margin-top: 5vh;
  text-align: center;
}

.down {
  text-align: center;
  font-size: 20px;
  width: 20vw;
  height: 5vh;
  font-family: 'Benne', Serif;
  display: block;
  margin-left: 40vw;
  
}

.welcome {
  text-align: center;
  font-family: 'Benne', serif;
  background: white;
  margin-top: 1em;
  margin-left: 15vw;
  margin-right: 15vw;
  margin-bottom: 8vh;
  padding-top: 8vh;
  padding-bottom: 2vh;
  font-size: 25px;
  border: 3px groove black;
}

@media screen and (max-width: 1025px) {
  .welcome {
    margin-left: 8vw;
    margin-right: 8vw;
    margin-top: 2vw;
    padding-top: 3vh;
    font-size: 35px;
  }
  .title {
    font-size: 57px;
    margin-top: 0px;
  }
}

@media screen and (min-device-width : 320px) and (max-device-width : 420px) {
  .welcome {
    font-size: 20px;
    margin-top: 25px;
    
  }

  .down {
    width: 50vw;
    margin-top: 2vh;
    margin-left: 25vw;
  }

  .title {
    font-size: 30px;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

