@import url('https://fonts.googleapis.com/css2?family=Bellota&family=Benne&family=Bentham&display=swap');

.card{
  background: white;
  width: 22rem;
  border: 2px groove black;
}

.card:hover{
  box-shadow: 5px 10px 20px 1px rgba(0,0,0,0.253)!important;
}

.card-body{
  padding: 3rem 0 !important;
}

.card-text{
  font-size: 1.5rem;
  padding: 0.4rem 1.9rem;
  font-family: 'Benne', serif;
  color: black;
}

.card-title {
font-family: 'Bentham', serif;
font-weight: 300;
font-size: 2.2rem;
border-bottom: 2px groove black;
border-top: 2px groove black;
}

.container-fluid.row {
  padding-top: 6rem;
}

.overflow{
  overflow: hidden;
  transition: transform 0.5s ease;
}

.card-img-top{
  transform: scale(1);
}

.card-img.top:hover{
  transform: scale(1.8);
}

.row {
  --bs-gutter-x: 6.0rem;
}

.text-secondary {
  color: black!important;
}

@media screen and (max-width: 1025px) {
  .row {
    --bs-gutter-x: 1.8rem;
  }

  .card{
    background: white;
    width: 15rem;
    border: 2px groove black;
  }

  .card-text{
    font-size: 44px;
    padding: 0.4rem 1.9rem;
    font-family: 'Benne', serif;
    color: black;
  }

  .card-title {
    font-size: 57px;
  }
}

@media screen and (min-device-width : 320px) and (max-device-width : 420px) {
.card {
  margin-left: 15vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.card-text{
  font-size: 25px;
  padding: 0.4rem;
  font-family: 'Benne', serif;
  color: black;
}

.card-title {
  font-size: 45px;
}
}