@import url('https://fonts.googleapis.com/css2?family=Bellota&family=Benne&family=Bentham&display=swap');

body {
  background-color: #DEC385;
}

.browseall {
  text-align: center;
  font-family: 'Benne', Serif;
  font-size: 5em;
}

.sip {
  text-align: center;
  font-family: 'Benne', Serif;
  font-size: 1.6em;
}


.footer {
  margin-top: 5vh;
  text-align: center;
}

.down {
  text-align: center;
  font-size: 20px;
  width: 20vw;
  height: 5vh;
  font-family: 'Benne', Serif;
  display: block;
  margin-left: 40vw;
  
}

.welcome {
  text-align: center;
  font-family: 'Benne', serif;
  background: white;
  margin-top: 1em;
  margin-left: 15vw;
  margin-right: 15vw;
  margin-bottom: 8vh;
  padding-top: 8vh;
  padding-bottom: 2vh;
  font-size: 25px;
  border: 3px groove black;
}

@media screen and (max-width: 1025px) {
  .welcome {
    margin-left: 8vw;
    margin-right: 8vw;
    margin-top: 2vw;
    padding-top: 3vh;
    font-size: 35px;
  }
  .title {
    font-size: 57px;
    margin-top: 0px;
  }
}

@media screen and (min-device-width : 320px) and (max-device-width : 420px) {
  .welcome {
    font-size: 20px;
    margin-top: 25px;
    
  }

  .down {
    width: 50vw;
    margin-top: 2vh;
    margin-left: 25vw;
  }

  .title {
    font-size: 30px;
  }
}